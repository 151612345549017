<template>
  <div id="home-wrapper">
    <div
      id="home-content"
      class="HomePage home-scroller"
    >
      <!-- <img
        v-if="$store.state.isMobile"
        src="/fonts/play_black.png"
      > -->
      <!-- {{ content }} -->
      <!-- SEO TITLE -->
      <h1 class="hidden">
        Binalogue website
      </h1>
      <h2 class="hidden">
        We are a global creative agency & production company specialized in
        audiovisual, graphic arts and interactive design. We partner with leading
        brands, OTT's and artists to conceptualize, direct and produce
        award-winning content all over the world. Through the power of teamwork
        and a multicultural kick-ass team of diversely talented artists we
        research, we discover, we learn. We contrast, we experiment, we play.
      </h2>


      <section class="HomePage__featured">
        <img
          v-if="$store.state.isMobile"
          class="HomePage__featured--image"
          :data-src="getImageKitURL(content.featured_a[0].thumbnail_9_16, 1, content.featured_a[0].image_type,false)"
          :src="getImageKitURL(content.featured_a[0].thumbnail_9_16,1,content.featured_a[0].image_type, false)"
          :alt="content.featured_a[0].title"
        >
        <img
          v-else
          class="HomePage__featured--image"
          :data-src="getImageKitURL(content.featured_a[0].thumb_carousel, 1, content.featured_a[0].image_type,false)"
          :src="getImageKitURL(content.featured_a[0].thumb_carousel,1,content.featured_a[0].image_type, false)"
          :alt="content.featured_a[0].title"
        >

        <InertiaLink
          class="HomePage__featured--content"
          :href="`/work/${content.featured_a[0].slug}/`"
        >
          <h5 class="HomePage__featured--client">
            {{ content.featured_a[0].client_subtitle }}
          </h5>
          <h3 class="HomePage__featured--title">
            {{ content.featured_a[0].title }}
          </h3>
          <h4 class="HomePage__featured--subtitle">
            {{ content.featured_a[0].subtitle }}
          </h4>
        </InertiaLink>
      </section>




      <section class="HomePage__bard scroll-home-bard">
        <div
          v-for="(bardItem, index) in content.home_bard"
          :key="`bardItem${index}`"
          class="HomePage__bard--container"
        >
          <div v-if="bardItem.type === 'set'">
            <div
              v-if="bardItem.attrs.enabled !== false || $page.props.auth.user"
              :class="{outline: bardItem.attrs.enabled === false && $page.props.auth.user}"
            >
              <component
                :is="bardItem.attrs.values.type"
                :content="bardItem.attrs.values"
                :position="index"
              />
            </div>
          </div>
        </div>
      </section>
      <span id="home-content-footer" />
      <TheFooter />
    </div>
  </div>

  <!-- <TheIntroVideo v-if="$store.state.isShownIntroVideo" /> -->
  <TheIntroVideo v-if="$store.state.isShownIntroVideo && $store.state.isShownTheCookieBanner" />
</template>

<script>
/* Vendor */
import { nextTick } from "vue";

import { InertiaLink } from "@inertiajs/inertia-vue3";
import { VueAgile } from "vue-agile";
// import { inject } from '@vue/runtime-core';
/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import imageKit from "@/mixins/image-kit";
import page from "@/mixins/page";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {
  name: "NewHomepage",
  components: {
    mainLayout,
    InertiaLink,
    agile: VueAgile
  },

  mixins: [imageKit, page, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      ChangeCurrentSlide: 1,
      agileSlider: {
        autoplay: true,
        timing: "ease-in-out",
        dots: false,
        navButtons: true,
        autoplaySpeed: 6000,
        infinite: this.customInfinite,
        pauseOnHover: false
        // centermode: true
      },
      customInfinite: true,

      // scroll animations
      bgScrollAnimation: null
    };
  },

  created() {
    //TODO: seotools
    seo.seoContent(this.content);
  },

  mounted() {
    if (this.content.featured_a.length < 2) {
      this.customInfinite = false;
    }

    this.$nextTick(() => {
      // console.log('init images' );
      this.imagesToPreload = document.querySelectorAll("img");
      console.log('home page imgs',this.imagesToPreload  );
      this.getBackgroundImagesUrl();
      this.loadImages();
    });
    this.pageMounted = true;
  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    customLength() {
      if (this.content.featured_a.length > 1) {
        return true;
      } else {
        return false;
      }
    },
    init() {
      if (this.$store.state.isMobile) {
        if (this.content.logo_white_mb) {
          this.toggleMenuColor("white", 0, 0);
        } else {
          this.toggleMenuColor("black", 0, 0);
        }
      } else {
        if (this.content.logo_white) {
          this.toggleMenuColor("white", 0, 0);
        } else {
          this.toggleMenuColor("black", 0, 0);
        }
      }
      this.setScroller("#home-wrapper", "#home-content");

      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to(".showBgHeader", {
        scrollTrigger: {
          trigger: "#home-content",
          markers: false,
          onEnter: this.updateHeaderState,
          onEnterBack: this.updateHeaderStateBack,
          start: "150px top",
          end: "top bottom",
          toggleActions: "play none reverse none"
        },
        height: "100%",
        duration: 0.2,
        ease: "Power2.out"
      });
    },

    updateHeaderState() {
      if (this.content.logo_white_mb_on_scroll && this.$store.state.isMobile) {
        this.toggleMenuColor("white", 0.2, 0);
      } else if (this.content.logo_white_on_scroll) {
        this.toggleMenuColor("white", 0.2, 0);
      } else {
        this.toggleMenuColor("black", 0.2, 0);
      }
    },

    updateHeaderStateBack() {
      if (this.$store.state.isMobile) {
        if (this.content.logo_white_mb) {
          this.toggleMenuColor("white", 0.2, 0.4);
        } else {
          this.toggleMenuColor("black", 0.2, 0.4);
        }
      } else {
        if (this.content.logo_white) {
          this.toggleMenuColor("white", 0.2, 0.4);
        } else {
          this.toggleMenuColor("black", 0.2, 0.4);
        }
      }
    },

    goToSlide(index) {
      this.$refs.agileCarousel.goTo(index);
      this.ChangeCurrentSlide = this.$refs.agileCarousel.currentSlide;
    },

    showCurrentSlide(event) {
      this.ChangeCurrentSlide = event.currentSlide;
    },

    customBgMobile(featuredA) {
      if (featuredA.thumbnail_9_16) {
        //return featuredA.thumbnail_9_16.filename;
        return this.getImageKitURL(
          featuredA.thumbnail_9_16,
          1,
          featuredA.image_type
        );
      } else {
        //return featuredA.thumbnail_4_5.filename;
        return this.getImageKitURL(
          featuredA.thumbnail_4_5,
          1,
          featuredA.image_type
        );
      }
    },

    customBgDesktop(featuredA) {
      //console.log(featuredA.thumb_carousel);
      if (featuredA.thumb_carousel) {
        //return featuredA.thumb_carousel.filename;
        return this.getImageKitURL(
          featuredA.thumb_carousel,
          1,
          featuredA.image_type
        );
      } else {
        //return featuredA.thumbnail_16_9.filename;
        return this.getImageKitURL(
          featuredA.thumbnail_16_9,
          1,
          featuredA.image_type
        );
      }
    }
  }
};
</script>

<style lang="scss">
.HomePage {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100vw;

  @include headerMargin;


  &__featured {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 9/16;
    @include marginBottom;

    @include tablet {
      aspect-ratio: 16/9;
    }

    &--image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: auto;
    }

    &--client {
      position: relative;
      font-size: 26px;
      color: $white;
      text-shadow: 0.1vw 0.1vw 1vw rgba($black, 0.3);
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;

      @include tablet-m {
        font-size: 2.3vw;
        text-shadow: 0.1vw 0.1vw 1vw rgba($black, 0.5);
      }

      @include desktop-l {
        font-size: 36px;
      }
    }

    &--content {
      position: relative;
      z-index: 2;
      text-align: center;
    }

    &--title {
      position: relative;
      padding: 0 5vw 10px;
      margin-bottom: 1vw;
      font-size: 46px;
      line-height: 1;
      color: $white;
      text-shadow: 0.2vw 0.2vw 0.7vw rgba($black, 0.4);
      letter-spacing: -1px;
      font-variation-settings: "wght" 600, "wdth" 100, "opsz" 30;

      @include tablet-m {
        padding: 0;
        font-size: 6vw;
        line-height: 1.2;
        text-shadow: 0.3vw 0.3vw 2vw rgba($black, 0.7);
        letter-spacing: -2px;
      }

      @include desktop-l {
        font-size: 100px;
      }
    }

    &--subtitle {
      position: relative;
      font-size: 26px;
      color: $white;
      text-shadow: 0.1vw 0.1vw 1vw rgba($black, 0.3);
      font-variation-settings: "wght" 500, "wdth" 100, "opsz" 30;

      @include tablet-m {
        font-size: 2.3vw;
        text-shadow: 0.1vw 0.1vw 1vw rgba($black, 0.5);
      }

      @include desktop-l {
        font-size: 36px;
      }
    }


  }



  &__bard {
    width: 100%;

    &--container {
      width: 100%;
      max-width: 100vw;
    }
  }
}
</style>
