<template>
  <div id="news-wrapper">
    <div
      id="news-content"
      class="News news-scroller"
    >
      <!-- SEO TITLE -->
      <h1 class="hidden">
        Press
      </h1>
      <section class="filters">
        <InertiaLink
          class="filters__item"
          :href="`${route('news.index')}/`"
          :class="{ active: $page.url === '/press/' }"
        >
          All
        </InertiaLink>
        <InertiaLink
          v-for="filter in content.news_filters"
          :key="filter"
          class="filters__item"
          :href="`/press/${filter}/`"
          :class="{
            active: $page.url.includes(filter),
          }"
        >
          <span
            v-for="tax in taxonomies"
            :key="tax.title"
          >
            <span v-if="filter === tax.slug">
              {{ tax.title }}
            </span>
          </span>
        </InertiaLink>
      </section>

      <!-- TODO: delete when merge to master -->
      <section
        v-if="$page.url === `/new-press/`"
        class="filters"
      >
        <InertiaLink
          class="filters__item"
          :href="`${route('newnews.index')}/`"
          :class="{ active: $page.url === `/new-press/` }"
        >
          new All
        </InertiaLink>
        <InertiaLink
          v-for="filter in content.news_filters"
          :key="filter"
          class="filters__item"
          :href="`/new-press/${filter}/`"
          :class="{
            active: $page.url.includes(filter),
          }"
        >
          <span
            v-for="tax in taxonomies"
            :key="tax.title"
          >
            <span v-if="filter === tax.slug">
              new {{ tax.title }}
            </span>
          </span>
        </InertiaLink>
      </section>

      <section
        v-if="$store.state.isMobile"
        class="News__grid"
      >
        <div class="News__column">
          <div
            v-for="(news, index) in content.news"
            :key="`news02${index}`"
            class="News__item"
          >
            <InertiaLink
              class="News__item--link"
              :href="`/press/${news.slug}/`"
            >
              <div class="News__item--image-container">
                <img
                  v-if="news.thumbnail"
                  :data-src="getImageKitURL(news.thumbnail, 3, news.image_type,false,index,'images')"
                  :src="getImageKitURL(news.thumbnail, 3, news.image_type,true,index,'images')"
                  :alt="news.title"
                  class="News__item--image"
                >
                <img
                  v-else
                  :src="`${$store.state.baseimageUrl}default-thumb-4-5.png`"
                  class="News__item--image"
                  alt="default image"
                >
              </div>
              <div class="News__text">
                <p class="small-subtitle grey">
                  <span
                    v-for="category in news.news_filters"
                    :key="category"
                  >
                    <span
                      v-for="tax in taxonomies"
                      :key="tax.title"
                    >
                      <span
                        v-if="category === tax.slug"
                        class="orange"
                      >
                        {{ tax.title }}
                      </span>
                    </span>. {{ formatDate(news.publication_date) }}
                  </span>
                </p>

                <h3 class="small-title">
                  {{ news.title }}
                </h3>
              </div>
            </InertiaLink>
          </div>
        </div>
      </section>

      <section
        v-else
        class="News__grid"
      >
        <div class="News__column">
          <div
            v-for="(news, index) in content.news"
            v-show="index % 3 === 0"
            :key="`news02${index}`"
            class="News__item"
          >
            <InertiaLink
              class="News__item--link"
              :href="`/press/${news.slug}/`"
            >
              <div class="News__item--image-container">
                <img
                  v-if="news.thumbnail"
                  :data-src="getImageKitURL(news.thumbnail, 3, news.image_type,false,index,'images')"
                  :src="getImageKitURL(news.thumbnail, 3, news.image_type,true,index,'images')"
                  :alt="news.title"
                  class="News__item--image"
                >
                <img
                  v-else
                  :src="`${$store.state.baseimageUrl}default-thumb-4-5.png`"
                  class="News__item--image"
                  alt="default image"
                >
              </div>
              <p class="small-subtitle grey">
                <span
                  v-for="category in news.news_filters"
                  :key="category"
                >
                  <span
                    v-for="tax in taxonomies"
                    :key="tax.title"
                  >
                    <span
                      v-if="category === tax.slug"
                      class="orange"
                    >
                      {{ tax.title }}
                    </span>
                  </span>. {{ formatDate(news.publication_date) }}
                </span>
              </p>

              <h3 class="small-title">
                {{ news.title }}
              </h3>
            </InertiaLink>
          </div>
        </div>

        <div class="News__column">
          <div
            v-for="(news, index) in content.news"
            v-show="(index + 1) % 3 !== 0 && index % 3 !== 0"
            :key="`news${index}`"
            class="News__item"
          >
            <InertiaLink
              class="News__item--link"
              :href="`/press/${news.slug}/`"
            >
              <div class="News__item--image-container">
                <img
                  v-if="news.thumbnail"
                  :data-src="getImageKitURL(news.thumbnail, 3, news.image_type,false,index,'images')"
                  :src="getImageKitURL(news.thumbnail, 3, news.image_type,true,index,'images')"
                  :alt="news.title"
                  class="News__item--image"
                >
                <img
                  v-else
                  :src="`${$store.state.baseimageUrl}default-thumb-4-5.png`"
                  class="News__item--image"
                  alt="default image"
                >
              </div>
              <p class="small-subtitle grey">
                <span
                  v-for="category in news.news_filters"
                  :key="category"
                >
                  <span
                    v-for="tax in taxonomies"
                    :key="tax.title"
                  >
                    <span
                      v-if="category === tax.slug"
                      class="orange"
                    >
                      {{ tax.title }}
                    </span>
                  </span>. {{ formatDate(news.publication_date) }}
                </span>
              </p>

              <h3 class="small-title">
                {{ news.title }}
              </h3>
            </InertiaLink>
          </div>
        </div>

        <div class="News__column">
          <div
            v-for="(news, index) in content.news"
            v-show="(index + 1) % 3 === 0"
            :key="`news${index}`"
            class="News__item"
          >
            <InertiaLink
              class="News__item--link"
              :href="`/press/${news.slug}/`"
            >
              <div class="News__item--image-container">
                <img
                  v-if="news.thumbnail"
                  :data-src="getImageKitURL(news.thumbnail, 3, news.image_type,false,index,'images')"
                  :src="getImageKitURL(news.thumbnail, 3, news.image_type,true,index,'images')"
                  :alt="news.title"
                  class="News__item--image"
                >
                <img
                  v-else
                  :src="`${$store.state.baseimageUrl}default-thumb-4-5.png`"
                  class="News__item--image"
                  alt="default image"
                >
              </div>


              <p class="small-subtitle grey">
                <span
                  v-for="category in news.news_filters"
                  :key="category"
                >
                  <span
                    v-for="tax in taxonomies"
                    :key="tax.title"
                  >
                    <span
                      v-if="category === tax.slug"
                      class="orange"
                    >
                      {{ tax.title }}
                    </span>
                  </span>. {{ formatDate(news.publication_date) }}
                </span>
              </p>
              <h3 class="small-title">
                {{ news.title }}
              </h3>
            </InertiaLink>
          </div>
        </div>
      </section>

      <span id="news-content-footer" />
      <TheFooter />
    </div>
  </div>
</template>

<script>
/* Vendor */
import { nextTick } from "vue";
import { InertiaLink } from "@inertiajs/inertia-vue3";
import moment from "moment";

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import page from "@/mixins/page";
import imageKit from "@/mixins/image-kit";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {
  components: {
    InertiaLink
  },

  mixins: [page, imageKit, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    },
    taxonomies: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      // scroll animations
      bgScrollAnimation: null
    };
  },

  created() {
    seo.seoContent(this.content);
  },


  mounted() {

    this.$nextTick(() => {
      // console.log('init images' );
      this.imagesToPreload = document.querySelectorAll("img");
      // console.log('home page imgs',this.imagesToPreload  );
      this.getBackgroundImagesUrl();
      this.loadImages();

    });
  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    init() {
      this.toggleMenuColor("black", 0, 0);
      this.setScroller("#news-wrapper", "#news-content");

      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to(".showBgHeader", {
        scrollTrigger: {
          trigger: "#news-content",
          markers: false,
          start: "50px top",
          end: "top bottom",
          toggleActions: "play none reverse none"
        },
        height: "100%",
        duration: 0.2,
        ease: "Power2.out"
      });

      setTimeout(() => {
        this.toggleMenuColor("black", 0, 0);
      }, 500);

    },

    formatDate(date) {
      return moment(date).format("MMMM DD, YYYY");
    }
  }
};
</script>

<style lang="scss">
.News {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include paddingPageTop;
  @include headerMargin;

  &__grid {
    display: flex;
    justify-content: space-between;
    width: 100vw;

    @include tablet {
      @include paddingMedium;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include tablet {
      width: 31%;
    }
  }

  &__text {
    padding: 0 6vw;

    @include tablet {
      padding: 0 5vw;
    }

    .small-subtitle {
      margin-bottom: 5px;
    }
  }

  &__item {
    width: 100%;
    @include marginBottom;

    &--link {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 0;
    }

    &--image-container {
      display: flex;
      width: 100%;
      height: auto;
      margin-bottom: 12px;
      overflow: hidden;

      @include tablet {
        margin-bottom: 20px;
      }
    }

    &--image {
      width: 100%;
      height: auto;

      @include desktop-s {
        transform: scale(1);
        transform-origin: center;
        @include transition;
      }

      &:hover {
        @include desktop-s {
          transform: scale(1.03);
        }
      }
    }

    .small-title {
      color: $grey-dark;
    }
  }
}
</style>
