<template>
  <div id="single-news-wrapper">
    <div
      id="single-news-content"
      class="SingleNews single-news-scroller"
    >
      <div
        v-if="$store.state.isMobile && content.top_bar_mobile"
        :style="`background : ${customColor};`"
        class="verticalSpaceMobile news-mobile-top-scroll"
      />

      <div
        v-if="content.top_bar_desktop && !$store.state.isMobile"
        :style="`background : ${customColor};`"
        class="verticalSpaceDesktop news-desktop-top-scroll"
      />
      <section
        v-for="(bardItem, index) in content.content_bard"
        :key="`bardItem${index}`"
      >
        <div v-if="bardItem.type === 'set'">
          <div
            v-if="bardItem.attrs.enabled !== false || $page.props.auth.user"
            :class="{outline: bardItem.attrs.enabled === false && $page.props.auth.user}"
          >
            <div v-if="bardItem.attrs.values.type === 'news_metadata'">
              <component
                :is="bardItem.attrs.values.type"
                :content="content"
                :content-metadata="bardItem.attrs.values"
              />
            </div>
            <component
              :is="bardItem.attrs.values.type"
              v-else
              :content="bardItem.attrs.values"
              :position="index"
            />
            <div />
          </div>
        </div>
      </section>
      <section
        v-if="content.related_news"
        class="Related scroll-related-news"
      >
        <h3 class="section-title news-section-title">
          Related news & projects<span class="orange">.</span>
        </h3>
        <div class="Related__container">
          <div
            v-for="(news, index) in content.related_news"
            :key="`news${index}`"
            class="Related__item"
          >
            <InertiaLink
              v-if="news.news_filters"
              class="Related__item--link"
              :href="`/press/${news.slug}/`"
            >
              <img
                v-if="news.square_thumbnail"
                :data-src="getImageKitURL(news.square_thumbnail, 3, news.image_type)"
                :src="getImageKitURL(news.square_thumbnail, 3, news.image_type,true)"
                class="Related__item--image"
                :alt="news.title"
              >
              <img
                v-else
                :src="`${$store.state.baseimageUrl}default-thumb-4-5.png`"
                class="Related__item--image"
                alt="default image"
              >
              <h3 class="small-title grey-dark mobile-padding">
                {{ news.title }}
              </h3>
              <h4 class="regular-text orange mobile-padding">
                <span
                  v-for="(category, idx) in customCategory(news.news_filters)"
                  :key="category"
                >
                  <span v-if="idx > 0">, </span>
                  {{ category }}
                </span>
              </h4>
            </InertiaLink>

            <InertiaLink
              v-else
              class="Related__item--link"
              :href="`/work/${news.slug}/`"
            >
              <img
                v-if="news.square_thumbnail"
                :data-src="getImageKitURL(news.square_thumbnail, 3, news.image_type)"
                :src="getImageKitURL(news.square_thumbnail, 3, news.image_type,true)"
                class="Related__item--image"
                :alt="news.title"
              >
              <img
                v-else
                :src="`${$store.state.baseimageUrl}default-thumb-4-5.png`"
                class="Related__item--image"
                alt="default image"
              >
              <h3 class="small-title grey-dark mobile-padding">
                {{ news.title }}
              </h3>
              <h4 class="regular-text orange mobile-padding">
                {{ news.subtitle }}
              </h4>
            </InertiaLink>
          </div>
        </div>
      </section>

      <span id="single-news-content-footer" />
      <TheFooter />
    </div>

    <span class="cursor cursorPlay">
      <TextPlay />
    </span>
  </div>
</template>

<script>
/* Vendor */
import { nextTick } from 'vue';
import { InertiaLink } from "@inertiajs/inertia-vue3";
import moment from "moment";

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import imageKit from "@/mixins/image-kit";
import page from "@/mixins/page";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {
  components: {
    InertiaLink
  },

  mixins: [imageKit, page, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      // scroll animations
      headerColorInit: null,
      bgScrollAnimation: null,
    };
  },

  computed: {
    customColor() {
      if (this.content.bg_top_bar) {
        return this.content.bg_top_bar;
      } else {
        return "transparent";
      }
    }
  },

  created() {
    seo.seoContent(this.content);
  },

  mounted() {
    this.$nextTick(() => {
      // console.log('init images' );
      this.imagesToPreload = document.querySelectorAll("img");
      // console.log('home page imgs',this.imagesToPreload  );
      this.getBackgroundImagesUrl();
      this.loadImages();
    });

    //check if we have to set the cursor animation
    if (!this.$store.state.isMobile) {
      const bardItems = [];

      for (let i = 0; i < this.content.content_bard.length; i++) {
        const element = this.content.content_bard[i];
        if (element.type === "set") {
          bardItems.push(element.attrs.values.type);
        }
      }

      if (bardItems.includes("video_pop_up")) {
        this.cursorAnimationsNews();
      }
    }
  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    init() {
      if (this.$store.state.isMobile) {
        if (this.content.menu_white_mobile) {
          this.toggleMenuColor("white", 0, 0);
        } else {
          this.toggleMenuColor("black", 0, 0);
        }
      } else {
        if (this.content.menu_white) {
          this.toggleMenuColor("white", 0, 0);
        } else {
          this.toggleMenuColor("black", 0, 0);
        }
      }

      if(this.$store.state.isMobile && !this.content.transparent_header) {
        this.$gsap.to('.showBgHeader', {height:'100%', duration:0});
      }

      this.setScroller("#single-news-wrapper", "#single-news-content");

      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to('.showBgHeader', {
        scrollTrigger: {
          trigger: '#single-news-scroller',
          markers: false,
          onEnter: this.updateHeaderState,
          onEnterBack: this.updateHeaderStateBack,
          start: '1px top',
          end: 'top bottom',
          toggleActions: 'play none reverse none',
        },
        height: '100%',
        duration: 0.2,
        ease: 'Power2.out',
      });
    },

    cursorAnimationsNews() {
      //cursor animation
      this.$gsap.set(".cursorPlay", { xPercent: -50, yPercent: -50 });

      const play = document.querySelector(".cursorPlay");
      const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
      const mouse = { x: pos.x, y: pos.y };
      const speed = 0.3;

      const fpms = 60 / 1000;

      const xSet = this.$gsap.quickSetter(play, "x", "px");
      const ySet = this.$gsap.quickSetter(play, "y", "px");

      window.addEventListener("mousemove", (e) => {
        mouse.x = e.x;
        mouse.y = e.y;
      });

      this.$gsap.ticker.add((time, deltaTime) => {
        const delta = deltaTime * fpms;
        const dt = 1.0 - Math.pow(1.0 - speed, delta);

        pos.x += (mouse.x - pos.x) * dt;
        pos.y += (mouse.y - pos.y) * dt;
        xSet(pos.x);
        ySet(pos.y);
      });
    },

    customCategory(categories) {
      let returnCats = [];
      for (let i = 0; i < categories.length; i++) {
        const cat = categories[i];
        switch (cat) {
          case "awards":
            returnCats.push("Awards");
            break;
          case "news":
            returnCats.push("News");
            break;
          case "project-feature":
            returnCats.push("Project Feature");
            break;
          case "talk":
            returnCats.push("Talk");
            break;
          case "interview":
            returnCats.push("Interview");
            break;
          default:
            returnCats.push(cat);
        }
      }

      return returnCats;
    },

    formatDate(date) {
      return moment(date).format("MMMM DD, YYYY");
    },

    updateHeaderState(){
      if (this.$store.state.isMobile) {
        if (this.content.logo_white_mb_on_scroll) {
          this.toggleMenuColor("white", 0.2,0);
        } else {
          this.toggleMenuColor("black", 0.2, 0);
        }
      } else {
        if (this.content.logo_white_on_scroll) {
          this.toggleMenuColor("white", 0.2, 0);
        } else {
          this.toggleMenuColor("black", 0.2, 0);
        }
      }
    },

    updateHeaderStateBack(){
      if (this.$store.state.isMobile) {
        if (this.content.menu_white_mobile) {
          this.toggleMenuColor("white", 0.2, 0.4);
        } else {
          this.toggleMenuColor("black", 0.2, 0.4);
        }
      } else {
        if (this.content.menu_white) {
          this.toggleMenuColor("white", 0.2, 0.4);
        } else {
          this.toggleMenuColor("black", 0.2, 0.4);
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.SingleNews {
  width: 100%;
  max-width: 100vw;
  @include headerMargin;

  .Related {
    width: 100%;

    .news-section-title {
      padding-top: 13vw;
      padding-bottom: 5vw;

      @include tablet {
        padding-top: 7.7vw;
        padding-bottom: 2vw;
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      @include paddingBig;
      @include paddingSectionBottom;
    }

    &__item {
      flex-grow: 0;
      width: 100%;
      @include marginBottomSmall;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &--image {
        width: 100%;
        margin-bottom: 10px;
      }

      @include tablet {
        margin-right: 2.33%;
        margin-bottom: 20px;
        width: 31.66%;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }

    a {
      color: $grey;

      &:hover {
        color: $orange;
      }
    }
  }
}

// cursor options
.cursorPlayNews {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  pointer-events: none;
  opacity: 0;

  svg {
    opacity: 1;
    fill: $white;
    height: 40px;
    width: auto;
  }

  &.black-true {
    svg {
      fill: $black;
    }
  }
}
</style>
