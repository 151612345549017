<template>
  <div id="clients-wrapper">
    <div
      id="clients-content"
      class="Clients clients-scroller"
    >
      <div class="SearchResult__container">
        <div class="SearchResult__input mobile-padding">
          <h1 class="SearchResult__title">
            Our projects for<span class="orange">: {{ content.title }}</span>
          </h1>
        </div>
      </div>

      <div class="Clients__container">
        <div
          v-for="(project, index) in content.related_projects"
          :key="`project${index}`"
          class="Clients__item"
        >
          <InertiaLink
            class="Client__item--link"
            :href="`/work/${project.slug}/`"
          >
            <div class="indexProject__image-container">
              <img
                v-if="project.thumbnail_4_5"
                :data-src="getImageKitURL(project.thumbnail_4_5, 3, project.image_type,false,index,'images')"
                :src="getImageKitURL(project.thumbnail_4_5, 3, project.image_type,true,index,'images')"
                :alt="project.title"
                class="indexProject__image"
              >
              <img
                v-else
                :src="`${$store.state.baseimageUrl}default-thumb-4-5.png`"
                class="indexProject__image"
                alt="default image"
              >
            </div>
          </InertiaLink>
          <InertiaLink
            :href="`/work/${project.slug}/`"
            class="indexProject__text block"
          >
            <h5 class="indexProject__client">
              {{ project.client_subtitle }}
            </h5>
            <h3 class="indexProject__title">
              {{ project.title }}
            </h3>
            <h4 class="indexProject__subtitle">
              {{ project.subtitle }}
            </h4>
          </InertiaLink>
        </div>
      </div>
      <span id="clients-content-footer" />
      <TheFooter />
    </div>
  </div>
</template>

<script>
/* Vendor */
import { nextTick } from "vue";
import { InertiaLink } from "@inertiajs/inertia-vue3";

/* Helpers */
import { mainLayout } from "@/helpers/vue-layouts";
import seo from "@/helpers/seo";

/* Mixins */
import imageKit from "@/mixins/image-kit";
import page from "@/mixins/page";
import scrollGlobal from "@/mixins/scroll-global";
import loader from "@/mixins/loader";

export default {
  components: {
    InertiaLink
  },

  mixins: [imageKit, page, scrollGlobal, loader],

  layout: mainLayout,

  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      // scroll animations
      bgScrollAnimation: null
    };
  },

  created() {
    seo.seoContent(
      this.content,
      `Our projects for:  ${this.content.title} - Binalogue - Global Creative & Production Company`
    );
  },

  mounted() {
    this.$nextTick(() => {
      // console.log('init images' );
      this.imagesToPreload = document.querySelectorAll("img");
      // console.log('home page imgs',this.imagesToPreload  );
      this.getBackgroundImagesUrl();
      this.loadImages();
    });
  },

  beforeUnmount() {
    this.bgScrollAnimation.scrollTrigger.kill();
  },

  methods: {
    init() {
      this.toggleMenuColor("black", 0, 0);
      this.setScroller("#clients-wrapper", "#clients-content");

      // HEADER ANIMATIONS
      this.bgScrollAnimation = this.$gsap.to(".showBgHeader", {
        scrollTrigger: {
          trigger: "#clients-content",
          markers: false,
          onEnter: this.updateHeaderState,
          onEnterBack: this.updateHeaderStateBack,
          start: "50px top",
          end: "top bottom",
          toggleActions: "play none reverse none"
        },
        height: "100%",
        duration: 0.2,
        ease: "Power2.out"
      });
    },
    updateHeaderState() {
      this.toggleMenuColor("black", 0.2, 0);
    },

    updateHeaderStateBack() {
      this.toggleMenuColor("black", 0.2, 0.4);
    }
  }
};
</script>

<style lang="scss">
.Clients {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include paddingPageTop;
  @include headerMargin;

  .SearchResult__container {
    margin-bottom: 0;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @include paddingMedium;
  }

  &__item {
    flex-grow: 0;
    width: 100%;
    @include marginBottom;

    @include tablet {
      margin-right: 2.33%;
      width: 31.66%;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
