<template>
  <div class="HomeThreeColumns">
    <div
      v-if="content.project01"
      class="indexProject HomeThreeColumns__first"
    >
      <div class="indexProject__content">
        <InertiaLink
          class="indexProject__image-container"
          :href="content.project01.collection == 'projects' ? `/work/${content.project01.slug}/`: `/press/${content.project01.slug}/`"
        >
          <div
            v-if="thumbnailRatio01[1]"
            class="indexProject__background-video"
          >
            <iframe
              :src="`https://player.vimeo.com/video/${thumbnailRatio01[1]}?background=1`"
              frameborder="0"
              allow="autoplay"
              width="100%"
              height="100%"
              :title="content.project01.title"
            />
          </div>
          <img
            v-if="thumbnailRatio01[0]"
            :data-src="getImageKitURL(thumbnailRatio01[0], 3, content.project01.image_type,false,position)"
            :src="getImageKitURL(thumbnailRatio01[0], 3, content.project01.image_type,true,position)"
            :alt="content.project01.title"
            class="indexProject__image"
            :class="{'opacity0':thumbnailRatio01[1] }"
          >
          <img
            v-else
            :src="getImageDefaultURL(content.thumbnail_ratio_01)"
            class="indexProject__image"
            alt="default image"
          >
        </InertiaLink>

        <InertiaLink
          class="indexProject__text"
          :href="content.project01.collection == 'projects' ? `/work/${content.project01.slug}/`: `/press/${content.project01.slug}/`"
        >
          <h5 class="indexProject__client">
            {{ content.project01.client_subtitle }}
          </h5>
          <h3 class="indexProject__title">
            {{ content.project01.title }}
          </h3>
          <h4 class="indexProject__subtitle">
            {{ content.project01.subtitle }}
          </h4>
          <h4
            v-if="content.project01.news_filters"
            class="indexProject__subtitle"
          >
            <span
              v-for="(newsFilter, i) in content.project01.news_filters"
              :key="`filter${i}`"
            >
              {{ newsFilter.title }}
            </span>
          </h4>
        </InertiaLink>
      </div>
    </div>

    <div
      v-else
      class="indexProject HomeThreeColumns__first hidden-mobile"
    >
      <div class="indexProject__content">
        <img
          src="/fonts/white-default.png"
          class="indexProject__image"
          alt="default image"
        >
      </div>
    </div>

    <div
      v-if="content.project02"
      class="indexProject HomeThreeColumns__second"
    >
      <div class="indexProject__content">
        <InertiaLink
          class="indexProject__image-container"
          :href="content.project02.collection == 'projects' ? `/work/${content.project02.slug}/`: `/press/${content.project02.slug}/`"
        >
          <div
            v-if="thumbnailRatio02[1]"
            class="indexProject__background-video"
          >
            <iframe
              :src="`https://player.vimeo.com/video/${thumbnailRatio02[1]}?background=1`"
              frameborder="0"
              allow="autoplay"
              width="100%"
              height="100%"
              :title="content.project02.title"
            />
          </div>
          <img
            v-if="thumbnailRatio02[0]"
            :data-src="getImageKitURL(thumbnailRatio02[0], 3, content.project02.image_type,false,position)"
            :src="getImageKitURL(thumbnailRatio02[0], 3, content.project02.image_type,true,position)"
            :alt="content.project02.title"
            class="indexProject__image"
            :class="{'opacity0':thumbnailRatio02[1] }"
          >
          <img
            v-else
            :src="getImageDefaultURL(content.thumbnail_ratio_02)"
            class="indexProject__image"
            alt="default image"
          >
        </InertiaLink>

        <InertiaLink
          class="indexProject__text"
          :href="content.project02.collection == 'projects' ? `/work/${content.project02.slug}/`: `/press/${content.project02.slug}/`"
        >
          <h5 class="indexProject__client">
            {{ content.project02.client_subtitle }}
          </h5>
          <h3 class="indexProject__title">
            {{ content.project02.title }}
          </h3>
          <h4 class="indexProject__subtitle">
            {{ content.project02.subtitle }}
          </h4>
          <h4
            v-if="content.project02.news_filters"
            class="indexProject__subtitle"
          >
            <span
              v-for="(newsFilter, i) in content.project02.news_filters"
              :key="`filter${i}`"
            >
              {{ newsFilter.title }}
            </span>
          </h4>
        </InertiaLink>
      </div>
    </div>

    <div
      v-else
      class="indexProject HomeThreeColumns__second hidden-mobile"
    >
      <div class="indexProject__content">
        <img
          src="/fonts/white-default.png"
          class="indexProject__image"
          alt="default image"
        >
      </div>
    </div>
    <div
      v-if="content.project03"
      class="indexProject HomeThreeColumns__third"
    >
      <div class="indexProject__content">
        <InertiaLink
          class="indexProject__image-container"
          :href="content.project03.collection == 'projects' ? `/work/${content.project03.slug}/`: `/press/${content.project03.slug}/`"
        >
          <div
            v-if="thumbnailRatio03[1]"
            class="indexProject__background-video"
          >
            <iframe
              :src="`https://player.vimeo.com/video/${thumbnailRatio03[1]}?background=1`"
              frameborder="0"
              allow="autoplay"
              width="100%"
              height="100%"
              :title="content.project03.title"
            />
          </div>
          <img
            v-if="thumbnailRatio03[0]"
            :data-src="getImageKitURL(thumbnailRatio03[0], 3, content.project03.image_type,false,position)"
            :src="getImageKitURL(thumbnailRatio03[0], 3, content.project03.image_type,true,position)"
            :alt="content.project03.title"
            class="indexProject__image"
            :class="{'opacity0':thumbnailRatio03[1] }"
          >
          <img
            v-else
            :src="getImageDefaultURL(content.thumbnail_ratio_03)"
            class="indexProject__image"
            alt="default image"
          >
        </InertiaLink>

        <InertiaLink
          class="indexProject__text"
          :href="content.project03.collection == 'projects' ? `/work/${content.project03.slug}/`: `/press/${content.project03.slug}/`"
        >
          <p class="indexProject__client">
            {{ content.project03.client_subtitle }}
          </p>
          <h3 class="indexProject__title">
            {{ content.project03.title }}
          </h3>
          <p class="indexProject__subtitle">
            {{ content.project03.subtitle }}
          </p>
          <h4
            v-if="content.project03.news_filters"
            class="indexProject__subtitle"
          >
            <span
              v-for="(newsFilter, i) in content.project03.news_filters"
              :key="`filter${i}`"
            >
              {{ newsFilter.title }}
            </span>
          </h4>
        </InertiaLink>
      </div>
    </div>

    <div
      v-else
      class="indexProject HomeThreeColumns__third hidden-mobile"
    >
      <div class="indexProject__content">
        <img
          src="/fonts/white-default.png"
          class="indexProject__image"
          alt="default image"
        >
      </div>
    </div>
  </div>
</template>

<script>
import imageKit from "@/mixins/image-kit";
import { InertiaLink, usePage } from "@inertiajs/inertia-vue3";

export default {
  components: {
    InertiaLink,
  },

  mixins: [imageKit],

  props: {
    position:{
      type: Number,
      default: 1000
    },
    content: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    thumbnailRatio01() {

      if(this.$store.state.isMobile){
        return [this.content.project01.thumbnail_4_5, this.content.project01.thumb_4_5_video];
      } else {
        switch (this.content.thumbnail_ratio_01) {
          case "square":
            return [this.content.project01.square_thumbnail, this.content.project01.thumb_1_1_video];
            break;
          case "sixteen-by-seven":
            return [this.content.project01.thumbnail_16_7, this.content.project01.thumb_16_7_video];
            break;
          case "four-by-five":
            return [this.content.project01.thumbnail_4_5, this.content.project01.thumb_4_5_video];
            break;

          default:
            return [this.content.project01.thumbnail_16_9, this.content.project01.thumb_16_9_video];
            break;
        }
      }

    },

    thumbnailRatio02() {
      if(this.$store.state.isMobile){
        return [this.content.project02.thumbnail_4_5, this.content.project02.thumb_4_5_video];
      } else {
        switch (this.content.thumbnail_ratio_02) {
          case "square":
            return [this.content.project02.square_thumbnail, this.content.project02.thumb_1_1_video];
            break;
          case "sixteen-by-seven":
            return [this.content.project02.thumbnail_16_7, this.content.project02.thumb_16_7_video];
            break;
          case "four-by-five":
            return [this.content.project02.thumbnail_4_5, this.content.project02.thumb_4_5_video];
            break;

          default:
            return [this.content.project02.thumbnail_16_9, this.content.project02.thumb_16_9_video];
            break;
        }
      }

    },

    thumbnailRatio03() {
      if(this.$store.state.isMobile){
        return [this.content.project03.thumbnail_4_5, this.content.project03.thumb_4_5_video];
      } else {
        switch (this.content.thumbnail_ratio_03) {
          case "square":
            return [this.content.project03.square_thumbnail, this.content.project03.thumb_1_1_video];
            break;
          case "sixteen-by-seven":
            return [this.content.project03.thumbnail_16_7, this.content.project03.thumb_16_7_video];
            break;
          case "four-by-five":
            return [this.content.project03.thumbnail_4_5, this.content.project03.thumb_4_5_video];
            break;

          default:
            return [this.content.project03.thumbnail_16_9, this.content.project03.thumb_16_9_video];
            break;
        }
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.HomeThreeColumns {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @include tablet {
    flex-wrap: nowrap;
    @include paddingMedium;
  }

  .indexProject {
    width: 100%;

    @include tablet {
      display: flex;
      width: 33.3333333%;
    }
  }

  &__first {
    @include tablet {
      padding-right: 1.33vw;
    }
  }

  &__second {
    @include tablet {
      padding: 0 0.66vw;
    }
  }

  &__third {
    @include tablet {
      padding-left: 1.33vw;
    }
  }
}
</style>
