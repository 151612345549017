<template>
  <div class="HomeTwoColumsStretch">
    <div
      v-if="content.project01"
      class="HomeTwoColumsStretch__image item01"
      :style="{ width: `${customWidth01}` + '%' }"
    >
      <InertiaLink
        class="indexProject__image-container"
        :href="content.project01.collection == 'projects' ? `/work/${content.project01.slug}/`: `/press/${content.project01.slug}/`"
      >
        <img
          v-if="thumbnailRatio01"
          :data-src="getImageKitURL(thumbnailRatio01, (customWidth01 > 50 ? 1.5 : 2), content.project01.image_type,false,position)"
          :src="getImageKitURL(thumbnailRatio01, (customWidth01 > 50 ? 1.5 : 2), content.project01.image_type,true,position)"
          :alt="content.project01.title"
          class="indexProject__image"
        >
        <img
          v-else
          :src="getImageDefaultURL(content.thumbnail_ratio_01)"
          class="indexProject__image"
          alt="default image"
        >
      </InertiaLink>
    </div>

    <div
      v-else
      class="indexProject HomeTwoColums__first hidden-mobile"
      :style="{ width: `${customWidth01}` + '%' }"
    >
      <div class="indexProject__content">
        <img
          src="/fonts/white-default.png"
          class="indexProject__image"
          alt="default image"
        >
      </div>
    </div>


    <div
      v-if="content.project02"
      class="HomeTwoColumsStretch__image item02"
      :style="{ width: `${customWidth02}` + '%' }"
    >
      <InertiaLink
        class="indexProject__image-container"
        :href="content.project02.collection == 'projects' ? `/work/${content.project02.slug}/`: `/press/${content.project02.slug}/`"
      >
        <img
          v-if="thumbnailRatio02"
          :data-src="getImageKitURL(thumbnailRatio02, (customWidth02 > 50 ? 1.5 : 2), content.project02.image_type,false,position)"
          :src="getImageKitURL(thumbnailRatio02, (customWidth02 > 50 ? 1.5 : 2), content.project02.image_type,true,position)"
          :alt="content.project02.title"
          class="indexProject__image"
        >
        <img
          v-else
          :src="getImageDefaultURL(content.thumbnail_ratio_02)"
          class="indexProject__image"
          alt="default image"
        >
      </InertiaLink>
    </div>

    <div
      v-else
      class="indexProject HomeTwoColums__first hidden-mobile"
      :style="{ width: `${customWidth02}` + '%' }"
    >
      <div class="indexProject__content">
        <img
          src="/fonts/white-default.png"
          class="indexProject__image"
          alt="default image"
        >
      </div>
    </div>

    <div
      v-if="content.project01"
      class="indexProject HomeTwoColumsStretch__text item03"
      :style="{ width: `${customWidth01}` + '%' }"
    >
      <InertiaLink
        class="indexProject__text"
        :href="content.project01.collection == 'projects' ? `/work/${content.project01.slug}/`: `/press/${content.project01.slug}/`"
      >
        <h5 class="indexProject__client">
          {{ content.project01.client_subtitle }}
        </h5>
        <h3 class="indexProject__title">
          {{ content.project01.title }}
        </h3>
        <h4 class="indexProject__subtitle">
          {{ content.project01.subtitle }}
        </h4>
        <h4
          v-if="content.project01.news_filters"
          class="indexProject__subtitle"
        >
          <span
            v-for="(newsFilter, i) in content.project01.news_filters"
            :key="`filter${i}`"
          >
            {{ newsFilter.title }}
          </span>
        </h4>
      </InertiaLink>
    </div>

    <div
      v-else
      class="indexProject HomeTwoColums__first"
      :style="{ width: `${customWidth01}` + '%' }"
    >
      <div class="indexProject__content" />
    </div>
    <div
      v-if="content.project02"
      class="indexProject HomeTwoColumsStretch__text item04"
      :style="{ width: `${customWidth02}` + '%' }"
    >
      <InertiaLink
        class="indexProject__text"
        :href="content.project02.collection == 'projects' ? `/work/${content.project02.slug}/`: `/press/${content.project02.slug}/`"
      >
        <h5 class="indexProject__client">
          {{ content.project02.client_subtitle }}
        </h5>
        <h3 class="indexProject__title">
          {{ content.project02.title }}
        </h3>
        <h4 class="indexProject__subtitle">
          {{ content.project02.subtitle }}
        </h4>
        <h4
          v-if="content.project02.news_filters"
          class="indexProject__subtitle"
        >
          <span
            v-for="(newsFilter, i) in content.project02.news_filters"
            :key="`filter${i}`"
          >
            {{ newsFilter.title }}
          </span>
        </h4>
      </InertiaLink>
    </div>
  </div>
</template>

<script>
import imageKit from "@/mixins/image-kit";
import { InertiaLink, usePage } from "@inertiajs/inertia-vue3";

export default {

  components: {
    InertiaLink,
  },

  mixins: [imageKit],

  props: {
    position:{
      type: Number,
      default: 1000
    },
    content: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    thumbnailRatio01() {
      if(this.$store.state.isMobile){
        return this.content.project01.thumbnail_4_5;
      } else {
        switch (this.content.thumbnail_ratio_01) {
          case 'square':
            return this.content.project01.square_thumbnail;
            break;
          case 'sixteen-by-seven':
            return this.content.project01.thumbnail_16_7;
            break;
          case 'four-by-five':
            return this.content.project01.thumbnail_4_5;
            break;

          default:
            return this.content.project01.thumbnail_16_9;
            break;
        }
      }

    },

    thumbnailRatio02() {
      if(this.$store.state.isMobile){
        return this.content.project02.thumbnail_4_5;
      } else {
        switch (this.content.thumbnail_ratio_02) {
          case 'square':
            return this.content.project02.square_thumbnail;
            break;
          case 'sixteen-by-seven':
            return this.content.project02.thumbnail_16_7;
            break;
          case 'four-by-five':
            return this.content.project02.thumbnail_4_5;
            break;

          default:
            return this.content.project02.thumbnail_16_9;
            break;
        }
      }

    },

    customWidth01() {
      if(this.$store.state.isMobile) {
        return 100;
      } else {
        return this.content.width;
      }
    },

    customWidth02() {
      if(this.$store.state.isMobile) {
        return 100;
      } else {
        return 100 - this.content.width;
      }
    },
  },
};
</script>

<style lang="scss">
.HomeTwoColumsStretch {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  @include tablet {
    @include paddingMedium;

    flex-wrap: wrap;
  }

  .indexProject__image-container {
    margin-bottom: 0;
    height: 100%;
  }

  .item01 {
    order: 1;
    margin-bottom: 12px;

    @include tablet {
      order: initial;
      padding: 0 1vw 0 0;
    }

    img {
      object-fit: cover;
    }
  }

  .item02 {
    order: 3;
    margin-bottom: 12px;

    @include tablet {
      order: initial;
      padding: 0 0 0 1vw;
    }

    img {
      object-fit: cover;
    }
  }


  .item03 {
    order: 2;

    @include tablet {
      order: initial;
      padding: 0 1vw 0 0;
    }
  }

  .item04 {
    order: 4;

    @include tablet {
      order: initial;
      padding: 0 0 0 1vw;
    }
  }
}
</style>
