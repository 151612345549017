<template>
  <div
    :id="`bg-video-${dynamicNumber}`"
    :width="videoWidth"
    :height="videoHeight"
    class="backgroundVideo"
  >
    <iframe
      v-if="$store.state.bgVideoUpdate"
      :src="`https://player.vimeo.com/video/${vimeo_id}?background=1`"
      :width="videoVimeoWidth"
      :height="videoVimeoHeight"
      frameborder="0"
      allow="autoplay"
      title="project video"
    />
    <span class="backgroundVideo__cover" />
  </div>
</template>

<script>

import { nextTick } from "vue";
export default {
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    vimeo_id: String,
    vimeo_ratio: String,

    contentWidth: {
      type:String,
      default:''
    },
  },

  data() {
    return {
      isShownBgVideo: false,
      videoWidth: 1600,
      videoHeight: 900,
      videoVimeoWidth: 1600,
      videoVimeoHeight: 900,
      plus: 9,
      divide: 16,
    };
  },

  computed: {
    dynamicNumber() {
      const randomNum = Math.floor(1000 + Math.random() * 9000);
      return randomNum;
    },
  },

  watch: {
    '$store.state.bgBackgroundHome'() {
      // this.$store.commit("TOGGLE_BG_BACKGROUND_HOME", false);
      this.getVideoSize(true, 'on inertia finish');
    },
  },

  mounted() {

    this.getVideoSize(true, 'initial');
    this.$nextTick(() => this.getVideoSize(true, 'on next tick'));


    window.addEventListener("resize", this.resize);

    setTimeout(() => {
      // console.log('resize after 1000');
      this.resize();
    }, 1000);

    setTimeout(() => {
      // console.log('resize after 2000');
      this.resize();
    }, 2000);

    setTimeout(() => {
      // console.log('resize after 4000');
      this.resize();
    }, 4000);

  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },

  methods: {
    updateVideo() {
      this.isShownBgVideo = true;
    },

    resize(){
      this.getVideoSize(false, 'on resize');
    },
    getVideoSize(scrollbarWidth, when) {
      switch (this.vimeo_ratio) {
        case "four-by-three":
          this.divide = 4;
          this.plus = 3;
          break;
        case "four-by-five":
          this.divide = 4;
          this.plus = 5;
          break;
        case "sixteen-by-five":
          this.divide = 16;
          this.plus = 5;
          break;
        case "sixteen-by-seven":
          this.divide = 16;
          this.plus = 7;
          break;
        case "two-by-one":
          this.divide = 2;
          this.plus = 1;
          break;
        case "square":
          this.divide = 1;
          this.plus = 1;
          break;
        case "sixteen-mm":
          this.divide = 2048;
          this.plus = 1240;
          break;
        case "nineteentwenty-by-thirteenfifty":
          this.divide = 1920;
          this.plus = 1350;
          break;
        case "nine-by-sixteen":
          this.divide = 9;
          this.plus = 16;
          break;
        case "nineteentwenty-by-sixfifty":
          this.divide = 1920;
          this.plus = 650;
          break;
        default:
          this.divide = 16;
          this.plus = 9;
          break;
      }

      //console.log('content width', this.contentWidth);

      if (document.getElementById("bg-video-" + this.dynamicNumber)) {

        if(this.$store.state.isMobile) {
          this.videoWidth = Math.ceil(
            document.getElementById("bg-video-" + this.dynamicNumber).offsetWidth
          );
          this.videoHeight = Math.ceil(
            (this.videoWidth * this.plus) / this.divide
          );
        } else {
          this.videoWidth = Math.ceil(
            document.getElementById("bg-video-" + this.dynamicNumber).offsetWidth
          );
          if (scrollbarWidth ) {
            this.videoWidth -= (15* this.contentWidth)/100;

          }

          this.videoHeight = Math.ceil(
            (this.videoWidth * this.plus) / this.divide
          );
        }



        this.videoVimeoHeight = this.videoHeight;
        this.videoVimeoWidth = this.videoWidth;

        //console.log(when, this.videoWidth, this.videoHeight);
      }
    }
  }
};
</script>


<style lang="scss">
.backgroundVideo {
  position: relative;
  display: flex;
  max-width: 100vw;
  overflow: hidden;
  width: 100%;

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    max-width: 100vw;
    background: transparent;
    width: 100%;
    height: 100%;
  }

  iframe {
    position: relative;
  }
}
</style>
